export const PAGE_LIMIT = 8
export const BIG_LIMIT = 1000

export const configureProfileVerification = (utils, dispatch, state) => ({
  initialize: (user_id) => {
    const room = { dispatch, request: ['userauth', 'securitychallenge', 'read'], request_info: { lookup_field: 'user', user: user_id }, auth: true}
    utils.action(room)
  },
  data: {
    security: state['userauth'],
  },
  action: {
    createEmailVerificationRequest: () => {
      const request = { dispatch, request: ['userauth', 'securitychallenge', 'create'], request_info: { security_type: 'EMAIL_OTP' }, auth: true}
      utils.action(request)
    },
    verifyAccount: (verification_code) => {
      const user_id = localStorage.getItem("user_id")
      const request = { dispatch, request: ['userauth', 'securitychallenge', 'partial_update'], request_info: { submitted_token: verification_code, lookup_field: 'user', user: user_id}, auth: true}
      utils.action(request)
    }
  }
})
