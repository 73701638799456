import {useEffect, Suspense, lazy, useState} from 'react'
import { Loader, Icon } from 'semantic-ui-react'
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import './App.css';
import {API_BASE_URL} from './index'
import axios from "axios";
import {configureProfileVerification} from 'modules/wd-aggregator/profile'
import { useDispatch, useSelector } from 'react-redux';
import { utils } from 'modules/wd-api-request-handler'

const About = lazy(() => import('./pages/mainsite/about'))
const Contact = lazy(() => import('./pages/mainsite/contact'))
const CourseBrief = lazy(() => import('./pages/mainsite/course-brief'))
const CourseContent = lazy(() => import('./pages/mainsite/course-content'))
const Courses = lazy(() => import('./pages/mainsite/courses'))
const LandingPage = lazy(() => import('./pages/mainsite/landingpage'))
const ForEducators = lazy(() => import('./pages/mainsite/for-educators'))
const ForCompanies = lazy(() => import('./pages/mainsite/for-companies'))
const ForInstitutions = lazy(() => import('./pages/mainsite/for-institutions'))

const Notification = lazy(() => import('./pages/common/notification'))
const Profile = lazy(() => import('./pages/common/profile'))
const PageNotFound = lazy(() => import('./pages/common/notfound'))
const Support = lazy(() => import('./pages/common/support'))

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const CheckSecurity = ({}) => {
  const dispatch = useDispatch();
  const [forceHide, setForceHide] = useState(false);
  const state = useSelector((state) => state);
  const {initialize, data, action} = configureProfileVerification(utils, dispatch, state)

  const { pathname } = useLocation();
  const userId = localStorage.getItem("user_id");
  const tk = localStorage.getItem("tk");
  const checkUserSecurity = async () => {
    if (userId) {
      initialize(userId)
    }
  }
  useEffect(() => {
    checkUserSecurity()
  }, [pathname]);

  if ((userId && tk) && !forceHide && data && data.security && data.security.error) {
    return (
      <div style={{backgroundColor: '#E37989', textAlign: 'center', padding: 5}}>
        <a href="/profile" style={{color: "#000" }}>Your account has not been verified yet. Please check <u>your profile</u> to activate or check status.</a>
        <Icon name="close" style={{float: "right", cursor: 'pointer'}} onClick={()=>{ setForceHide(true) }}/>
      </div>
    )
  } else if (!forceHide && data?.security?.securitychallenge_item?.stage === 'EMAIL_VERIFICATION_PENDING') {
    return(
      <div style={{backgroundColor: '#7ae2b5', textAlign: 'center', padding: 5}}>
        <a href="/profile" style={{color: "#000" }}>Verification link is sent to your email. Please <u>verify your account</u> by clicking the link in your email.</a>
        <Icon name="close" style={{float: "right", cursor: 'pointer'}} onClick={()=>{ setForceHide(true) }}/>
      </div>
    )
  }
  return null;
};

function App() {
  return (
      <Router>
        <Suspense fallback={<Loader active inline style={{margin: 10}}/>}>
          <ScrollToTop />
          <CheckSecurity/>
          <Routes>
             <Route exact path='/' element={<LandingPage/>}></Route>
             <Route exact path='/contact' element={<Contact/>}></Route>
             <Route exact path='/about' element={<About/>}></Route>
             <Route exact path='/course/:slug' element={<CourseBrief/>}></Route>
             <Route exact path='/learn/:slug' element={<CourseContent/>}></Route>
             <Route exact path='/section/:category' element={<Courses section/>}></Route>
             <Route exact path='/courses/search/:query' element={<Courses/>}></Route>
             <Route exact path='/courses' element={<Courses/>}></Route>
             <Route exact path='/notification' element={<Notification/>}></Route>
             <Route exact path='/profile' element={<Profile switch_profile_allowed={true} show_external_link={true} />}></Route>
             <Route exact path='/for-educators' element={<ForEducators/>}></Route>
             <Route exact path='/for-companies' element={<ForCompanies/>}></Route>
             <Route exact path='/for-institutions' element={<ForInstitutions/>}></Route>
             <Route exact path='/support' element={<Support/>}></Route>
             <Route exact path='*' element={<PageNotFound/>}></Route>
          </Routes>
        </Suspense>
      </Router>
  );
}

export default App;
